import { css } from "@emotion/react"
import { Link } from "gatsby"
import React from "react"
import { useLocation } from "@reach/router"

const containerStyles = css`
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background: black;
  padding: 20px 0;

  h2:first-of-type {
    border-right: 1px solid;
    padding-right: 10px;
  }

  h2:last-of-type {
    padding-left: 10px;
  }

  .active_link {
    text-decoration: underline;
  }
`

const linkStyles = css`
  margin: 0;
  display: inline-block;

  a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    font-weight: bold;

    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

export const ServicesNav = () => {
  const location = useLocation()

  const isIndexRoute = location.pathname === "/services"

  return (
    <div css={containerStyles}>
      <h2 css={linkStyles}>
        <Link
          to={"/services/videography"}
          activeClassName="active_link"
          css={[
            isIndexRoute &&
              css`
                text-decoration: underline !important;
              `,
          ]}
        >
          VIDEOGRAPHY
        </Link>
      </h2>
      <h2 css={linkStyles}>
        <Link to={"/services/photography"} activeClassName="active_link">
          PHOTOGRAPHY
        </Link>
      </h2>
    </div>
  )
}
