import { css } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { InformationItem } from "../../components/information-item"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { ServicesNav } from "../../components/services-nav"
import { isOdd } from "../../utils/isOdd"
import { mediaQuery } from "../../utils/mediaQuery"

const sectionStyles = theme => css`
  ${mediaQuery[1]} {
    padding-top: ${theme.heights.header};
  }
`

const Videography = () => {
  const data = useStaticQuery(graphql`
    query {
      allVideographyJson {
        edges {
          node {
            title
            description {
              description
            }
            videoSrc
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Videography" />
      <section css={sectionStyles}>
        <ServicesNav />
        {data.allVideographyJson.edges.map((item, index) => (
          <InformationItem
            key={index}
            data={item.node}
            isOdd={isOdd(index)}
            hasModal={false}
            isVideoItem
          />
        ))}
      </section>
    </Layout>
  )
}

export default Videography
